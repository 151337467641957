import { type AxiosResponse } from 'axios'
import BaseService from '@/services/base-service'

export default class DatasetsService extends BaseService {
  fetchDatasets = async (): Promise<IDataset[]> => {
    const response: AxiosResponse<IDataset[]> = await this.http.get(
      '/api-internal/v1/blanc/dataset/',
    )
    return response.data
  }

  fetchDatasetRuns = async (id: number): Promise<IDatasetRun[]> => {
    const response: AxiosResponse<IDatasetRun[]> = await this.http.get(
      `/api-internal/v1/blanc/dataset-run/?dataset=${id}`,
    )
    return response.data
  }

  fetchDatasetRunsByAlias = async (alias: string): Promise<IDatasetRun[]> => {
    const response: AxiosResponse<IDatasetRun[]> = await this.http.get(
      `/api-internal/v1/blanc/dataset-run/?dataset__alias=${alias}`,
    )
    return response.data
  }

  fetchDatasetRunReport = async (id: number): Promise<IDatasetReport> => {
    const response: AxiosResponse<IDatasetReport> = await this.http.get(
      `/api-internal/v1/blanc/dataset-run/${id}/report/`,
    )
    return response.data
  }

  fetchDatasetRunReportByAliasAndPK = async (
    jobRunId: string,
    alias: string,
  ): Promise<IDatasetReport> => {
    const response: AxiosResponse<IDatasetReport> = await this.http.get(
      `/api-internal/v1/blanc/job-run/${jobRunId}/dataset/${alias}/report/`,
    )
    return response.data
  }
}
