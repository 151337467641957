import { defineStore } from 'pinia'
import type { IDataset, IDatasetRun } from '@/stores/types/dataset'
import type { IDatasetReport } from '@/stores/types/report'
import DatasetsService from '@/services/datasets-service'
import { DatasetView } from '@/utils/enums'

export const useDatasetsStore = defineStore('datasetsStore', () => {
  const datasetsService = new DatasetsService()
  // State
  const datasets: Ref<IDataset[]> = ref([])
  const datasetRuns: Ref<IDatasetRun[]> = ref([])
  const datasetRunReport: Ref<IDatasetReport> = ref({} as IDatasetReport)
  const datasetTags: Ref<string[]> = ref([])
  const jobNames: Ref<string[]> = ref([])
  const datasetPageView = ref(DatasetView.Dataset)

  // Mutations
  const updateDatasets = (value: IDataset[]) => {
    datasets.value = value
    // Dataset filterBy list of dataset tags and job names.
    datasetTags.value = [
      ...new Set(
        datasets.value.map((ds: IDataset) => ds.dataset_tag.toString()),
      ),
    ]
    datasetTags.value.unshift('All')
    jobNames.value = [
      ...new Set(datasets.value.map((ds: IDataset) => ds.job_name.toString())),
    ]
    jobNames.value.unshift('All')
  }

  const updateDatasetRuns = (value: IDatasetRun[]) => {
    datasetRuns.value = value
  }

  const updateDatasetRunReport = (
    value: IDatasetReport = {} as IDatasetReport,
  ) => {
    if (value && value.failed_checks) {
      value.failed_checks = value.failed_checks
        .map((failedCheck: IFailedCheck) => ({
          ...failedCheck,
          valueDifference: HelperMethods.valueDifference(
            failedCheck.expected_value,
            failedCheck.actual_value,
            5,
          ),
        }))
        .sort((a, b) => Number(b.valueDifference) - Number(a.valueDifference))
    }
    datasetRunReport.value = value
  }

  const setActiveDatasetView = (activeVIew: DatasetView) => {
    datasetPageView.value = activeVIew
  }

  // Actions
  const fetchDatasets = async () => {
    updateDatasets([])
    await datasetsService.fetchDatasets().then((res: IDataset[]) => {
      updateDatasets(res)
    })
  }

  const fetchDatasetRuns = async (id: number) => {
    updateDatasetRuns([])
    await datasetsService.fetchDatasetRuns(id).then((res: IDatasetRun[]) => {
      updateDatasetRuns(res)
    })
  }

  const fetchDatasetRunsByAlias = async (alias: string) => {
    updateDatasetRuns([])
    await datasetsService
      .fetchDatasetRunsByAlias(alias)
      .then((res: IDatasetRun[]) => {
        updateDatasetRuns(res)
      })
  }

  const fetchDatasetRunReport = async (id: number) => {
    updateDatasetRunReport()
    await datasetsService
      .fetchDatasetRunReport(id)
      .then((res: IDatasetReport) => {
        updateDatasetRunReport(res)
      })
  }

  const fetchDatasetRunReportByAliasAndPK = async (
    jobRunId: string,
    alias: string,
  ) => {
    updateDatasetRunReport()
    await datasetsService
      .fetchDatasetRunReportByAliasAndPK(jobRunId, alias)
      .then((res: IDatasetReport) => {
        updateDatasetRunReport(res)
      })
  }

  return {
    datasets,
    datasetRuns,
    datasetRunReport,
    datasetTags,
    jobNames,
    datasetPageView,
    updateDatasets,
    updateDatasetRuns,
    setActiveDatasetView,
    fetchDatasets,
    fetchDatasetRuns,
    fetchDatasetRunReport,
    fetchDatasetRunsByAlias,
    fetchDatasetRunReportByAliasAndPK,
  }
})
